import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { StyledLogin, Wrapper,  buttonAllAccounts, buttonAllAplicants } from './styles/StyledLogin'
import { Typography, Colors, Images } from '../themes'
import Container from '../components/container'
import Input from '../components/input'
import Button from '../components/button'

import AuthActions from '../redux/AuthRedux'
import StartupActions from '../redux/StartupRedux'
import { Providers, ProviderState } from '@microsoft/mgt-element';

import { USER_TYPE } from '../constants/'

const { Title, ErrorText } = Typography

export class Login extends Component {
  static getDerivedStateFromProps(props, state) {
    const { account, history, initToken, qrCode } = props
    const { username, password, currentInstance } = state

    let returnValue = {}

    // if (qrCode && !qrCode.fetching && currentInstance.signin) {
    //   if (qrCode.payload) {
    //     if (qrCode.payload.type === 'SUCCESS') {
    //       returnValue = {
    //         errorText: '',
    //         loading: false,
    //         showQRForm: true,
    //       }
    //       currentInstance.signin = false
    //     } else {
    //       if (qrCode.payload && qrCode.payload.data.message) {
    //         returnValue = {
    //           errorText: qrCode.payload.data.message,
    //           loading: false,
    //         }
    //       }
    //       currentInstance.signin = false
    //     }
    //   }
    //   if (qrCode.error) {
    //     returnValue = {
    //       errorText: 'Something wrong, please check your internet connection',
    //       loading: false,
    //     }
    //     currentInstance.signin = false
    //   }
    // }
    if (account && !account.fetching && currentInstance.signin) {
      if (account.payload) {
        if (account.payload.type === 'SUCCESS') {
          if (account.payload.data.account) {
            returnValue = {
              errorText: '',
              loading: false,
            }
            initToken()
            currentInstance.signin = false
            history.replace('/')
          } 
        } else {
          initToken()
          if (account.payload && account.payload.data.message) {
            returnValue = {
              errorText: account.payload.data.message,
              loading: false,
            }
          }

          currentInstance.signin = false
        }
      }

      if (account.error) {
        initToken()
        returnValue = {
          errorText: 'Something wrong, please check your internet connection',
          loading: false,
        }
        currentInstance.signin = false
      }
    }

    return {
      ...returnValue
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      errorText: '',
      loading: false,
      currentInstance: this,
      showQRForm: false,
      otp: '',
    }

    this._onChangeText = this._onChangeText.bind(this)
    this._onPressLogin = this._onPressLogin.bind(this)
    this._onPressVerifyToken = this._onPressVerifyToken.bind(this)
    this.signin = false
    this.verifyQR = false
  }

  _onChangeText(name, value) {
    this.setState({ [name]: value })
  }

  _onPressLogin() {
    const { signin, signin2FA } = this.props
    const { username, password } = this.state

    const params = {
      "credential" : {
        "credentialType": "USERNAME",
        "credentialData": {
            username,
            password
        }
      }  
    }

    signin2FA({ data: params })
    this.setState({ loading: true })
    this.signin = true
  }

  handleLoginClicked = async () => {
    this.setState({loading: true})
    await Providers.globalProvider.login();
  
    Providers.globalProvider.setState(ProviderState.SignedIn)
  }

  async componentDidMount() {     
    const updateState = async () => {
      const provider = Providers.globalProvider;
      if(provider && provider.state === ProviderState.SignedIn) {
        let me = await Providers.globalProvider.graph.client.api('/me').get();
          if (me && me.mail) {
            this.setState({loading: true})
            this.signin = true
            this.props.signinSSO({data: me})
          }
      }
    };
    Providers.onProviderUpdated(updateState)
  }

  _onPressVerifyToken() {
    const {verifyOTP, qrCode} = this.props
    const { username, password, otp } = this.state
    let params = {}
    if (qrCode && qrCode.payload.data && qrCode.payload.data && qrCode.payload.data.account) {
      params = {
        "otp": otp,
        "secret": qrCode && qrCode.payload.data && qrCode.payload.data && qrCode.payload.data.secret,
        "account": qrCode && qrCode.payload.data && qrCode.payload.data && qrCode.payload.data.account
      }
    } else {
      params = {
        "credential" : {
          "credentialType": "USERNAME",
          "credentialData": {
              username,
              password
          }
        },
        "otp": otp,
        "secret": qrCode && qrCode.payload.data && qrCode.payload.data && qrCode.payload.data.secret
      }
    }
    verifyOTP({ data: params })
    this.setState({ loading: true })
    this.verifyQR = true
  }

  render() {
    const { errorText, loading, username, password, showQRForm, otp, } = this.state
    let disabled = username === '' || password.length < 6 || loading

    const { account, qrCode } = this.props
    if (account && !account.fetching) {
      if (account.payload) { 
        if (account.payload.type === 'SUCCESS') {
          return <Redirect to="/users" />
          // if (account.payload.data.account.type === USER_TYPE.ADMIN) {
          //   this.props.initToken()
          //    return <Redirect to="/users" />
          // }
          // if (account.payload.data.account.type === USER_TYPE.PARTNER) {
          //   this.props.initToken()
          //    return <Redirect to="/dashboard" />
          // }
        }
      }
    }

    return (
      <Container>
        <StyledLogin>
          <Wrapper>
            <Title customStyle={` color: black; `}>CARA Dashboard</Title>
            {showQRForm ?
            <>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                <img src={qrCode && qrCode.payload.data && qrCode.payload.data.qr ? qrCode.payload.data.qr : ''} alt="QRCode" style={{width: 150, height: 150}}/>
              </div>
              <div>Please scan this QRCode using microsoft authenticators</div>
              <Input inputProps={{ placeholder: "input otp", onChange: (e) => this._onChangeText('otp', e.target.value), value: otp }} containerStyle={` margin-top: 33px; `} />
              {
                errorText !== '' &&
                  <ErrorText customStyles={`margin-bottom: 15px;`}>
                    {errorText}
                  </ErrorText>
              }
              <Button 
                text={loading ? 'Loading...' : "Verify"}
                onClick={this._onPressVerifyToken}
                backgroundColor={Colors.color37}
              />
            </>
            :
            <>
            <Input inputProps={{ placeholder: "Username", onChange: (e) => this._onChangeText('username', e.target.value), value: username }} containerStyle={` margin-top: 33px; `} />
            <Input inputProps={{ type: 'password', placeholder: "Password", onChange: (e) => this._onChangeText('password', e.target.value), value: password }}  containerStyle={` margin-bottom: 33px; `}  />
            {
              errorText !== '' &&
                <ErrorText customStyles={`margin-bottom: 15px;`}>
                  {errorText}
                </ErrorText>
            }
            <Button 
              text={loading ? 'Loading...' : "Login"}
              onClick={this._onPressLogin}
              disabled={disabled}
            />
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10, paddingBottom: 10}}>Or</div>
            {/* <img src={Images.microsoftLogo} style={{width: "100%", height: 45, objectFit: 'contain'}} onClick={this.handleLoginClicked} /> */}
            <Button 
              text={loading ? 'Loading...' : "Sign in with Microsoft"}
              onClick={this.handleLoginClicked}
              iconLeftImage={Images.microsoftLogo}
              backgroundColor={Colors.color3}
              textColor={Colors.color}
              iconLeftStyle={{width: 20, height: 20, objectFit: "contain", marginRight: 14}}
              borderColor={Colors.color}
              bordered={true}
            />
            <div className="buttonContainer">
              <Button 
                text="Forget Username"
                buttonStyle={buttonAllAccounts}
                backgroundColor={Colors.color10}
                textColor={undefined}
                containerStyle={` width: 160px; `}
                onClick={() => this.props.history.push('/forget-username')}
              />
              <div className="buttonSeparator" />
              <Button 
                text="Forget Password"
                backgroundColor={Colors.color14}
                textColor={Colors.color3}
                buttonStyle={buttonAllAplicants}
                textStyle={` margin: 0 10px; `}
                onClick={() => this.props.history.push('/forget-password')}
              />
            </div>
            </>}
          </Wrapper>
        </StyledLogin>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  qrCode: state.auth.qrCode,
  account: state.auth.account
})

const mapDispatchToProps = (dispatch) => {
  return {
    signin: (params) => dispatch(AuthActions.signinRequest(params)),
    signin2FA: (params) => dispatch(AuthActions.getQRCodeRequest(params)),
    signinSSO: (params) => dispatch(AuthActions.checkAdminAccountRequest(params)),
    verifyOTP: (params) => dispatch(AuthActions.verifyQRRequest(params)),
    initToken: () => dispatch(StartupActions.initToken()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
