import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import moment from 'moment'
import axios from 'axios'
import memoize from "memoize-one";
import _ from 'lodash'
import Swal from 'sweetalert2';

import { StyledUserDetail, } from './styles/StyledUserDetail'

import { Images, Colors, } from '../themes'

import { ACCOUNT_STATUS, USER_TYPE } from '../constants'

import  UserActions from '../redux/UserRedux'
import  AuthActions from '../redux/AuthRedux'

import Dialog from '../components/modal'
import Input from '../components/input'
import Button from '../components/button'
import { TagCaregiver, TagDementia } from '../components/dialog-tag'
import DialogInfo from '../components/dialog-info'

import ProccessData from '../lib/ProccessData'
import { deleteUser, editPartner, updatePartnerAccountStatus, getOtherAccount, tagAccount, deleteOtherAccount } from '../api/UserAPI'
import { addNotification, } from '../api/NotificationAPI';

const defaultDialogState = {
  dialogTitle: '', 
  titleColor: '',
  dialogDescription: '',
  dialogOkText: '',
  okBorderColor: '',
  okTextColor: '',
  dialogCancelText: '',
  okBordered: true,
  okBackgroundColor: 'transparent',
}

const deleteDialogState = {
  dialogTitle: 'Delete Account', 
  titleColor: Colors.color13,
  dialogDescription: 'Are you sure you want to delete this account?',
  dialogOkText: 'Delete',
  okBorderColor: Colors.color13,
  okTextColor: Colors.color13,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: 'transparent',
}

const rejectDialogState = {
  dialogTitle: 'Reject Account', 
  titleColor: Colors.color19,
  dialogDescription: 'Are you sure you want to reject this account?',
  dialogOkText: 'Reject',
  okBorderColor: Colors.color7,
  okTextColor: Colors.color2,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: 'transparent',
}

const approveDialogState = {
  dialogTitle: 'Approve Account', 
  titleColor: Colors.color17,
  dialogDescription: 'Are you sure you want to approve this account?',
  dialogOkText: 'Approve',
  okBorderColor: Colors.color17,
  okTextColor: Colors.color17,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: 'transparent',
}

const deleteTagDialogState = {
  dialogTitle: 'Delete Tag Account Relationship', 
  titleColor: Colors.color13,
  dialogDescription: 'Are you sure you want to delete this tag account relationship?',
  dialogOkText: 'Delete',
  okBorderColor: Colors.color13,
  okTextColor: Colors.color13,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: 'transparent',
}

const alertError = (e) => {
  Swal.fire({
    title: 'Error!',
    text: e,
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

export class PartnerDetail extends Component {
  static getDerivedStateFromProps(props, state) {
    const { mounted } = state
    const { users, match, } = props

    let returnValue = {}

    if (!mounted) {
      const user = users && users.payload && users.payload.data.filter(data => data.uid === parseInt(match.params.id))[0]

      returnValue = {
        user,
      }
      
      if (user) {
        const { uid, createdAt, username, otherAccount } = user
        returnValue = {
          uid,
          user,
          partnerDetails: user.partnerDetails ? user.partnerDetails : user.partnerDetail ? user.partnerDetail : {},
          mounted: true,
          otherAccount,
        }
      }
    }
    return {
      ...returnValue
    }
  }
  constructor(props) {
    super(props)
    

    this.state = {
      uid: null,
      user: null,
      dialogConfirmation: false,
      loading: false,
      genderDropdown: false,
      mounted: false,
      loadingEdit: false,
      dialogReject: false,
      loadingAccountStatus: false,
      ...defaultDialogState,
      tagsId: null,
      otherUid: null,
      otherFirstname: '',
      otherLastname: '',
      relationship: '',
      otherGenderDropdown: false,
      otherGender: {},
      otherBirth: null,
      otherLanguageDropdown: false,
      otherLanguage: {},
      otherEmail: '',
      otherPhone: '',
      otherAccount: [],
      dialogTagCaregiver: false,
      dialogTagDementia: false,
      dialogSuccessTag: false,
      successTagMessage: '',
      loadingTag: false,
      idTag: null,
      type: '',
      infoDialogShow: false,
      infoDescription: '',
      partnerDetails: {},
    }

    this._onOkDialog = this._onOkDialog.bind(this)
    this._onUpdateAccountStatus = this._onUpdateAccountStatus.bind(this)
  }

  componentDidMount() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    this.props.getUsers({ data: { uid: this.props.match.params.id } })
  }

  renderList(stateName, field, value, className = '', dropdown = {}, inputProps = { type: 'text' }) {
    const { action } = this.props.location.state || ''

    const isInput = Object.keys(dropdown).length === 0
    const type = inputProps
    return (
      <Row style={{ width: '100%', marginLeft: '.1px' }} className={`list-container ${action === 'DETAIL' ? className : ''}`}> 
        <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
          <Row>
            <Col xs={5}>
              <p>{field}</p>
            </Col>
            <Col xs={7}>
              {
                action === 'DETAIL' ?
                  <div style={{ display: 'flex', }}>
                    <p style={{ marginRight: '22px', }}>: </p>
                    <p style={{ width: '85%' }}>{value ? value : '-'}</p>
                  </div>
                : 
                  <div style={{ display: 'flex', }}>
                    <p style={{ marginRight: '22px', }}>: </p>
                    <div style={{ width: '84%', }}>
                      <Input inputProps={{ 
                        value, 
                        readOnly: isInput ? false : true, 
                        onClick: () => !isInput && dropdown.onClick(), onChange: (e) => {
                          let currentPartnerDetails = { ...this.state.partnerDetails }
                          currentPartnerDetails[stateName] = e.target.value
                          this.setState({ partnerDetails: currentPartnerDetails })
                        },
                        maxLength: 50, 
                        ...inputProps, 
                      }} 
                        containerStyle={` margin:-8px 0 0 0; `} {...dropdown} dropdownWidth={`100%`} />
                    </div>
                  </div>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  deleteUser = async (uid) => {
    this.setState({ loading: true })
    try {
      let resultDelete = {}
      resultDelete.fetching = false
      resultDelete.payload = await deleteUser({ uidList: uid, })
      ProccessData(resultDelete, 'delete user')
      .then(res => {
        this.setState({ 
          dialogConfirmation: false,
          loading: false,
         })
        this.onDialogInfo('Account Deleted')
        this.props.history.goBack()
      })
      .catch(err => {
        this.onDialogInfo(err)
        this.setState({
          dialogConfirmation: false,
          loading: false,
        })
      })
    } catch (e) {
      this.onDialogInfo(e.message)
      this.setState({
        loading: false,
        dialogConfirmation: false,
      })
    }
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  onEditAccount = async () => {
    this.setState({ loadingEdit: true })
    
    try {
      let resultEdit = {}
      resultEdit.fetching = false
      const { partnerDetails } = this.state

      const partnerErrorForm = { 
        organizationName : partnerDetails.organizationName,
        postalCode : partnerDetails.postalCode,
        address : partnerDetails.address,
        pocPhone : partnerDetails.pocPhone,
        pocName : partnerDetails.pocName,
        pocEmail : partnerDetails.pocEmail,
        pocTitle: partnerDetails.jobTitle,
        hotlineNumber : partnerDetails.hotlineNumber
      }

      let foundError = false

      for (const item in partnerErrorForm) {
        const itemString = partnerErrorForm[item] == null ? '' : partnerErrorForm[item]
        const itemDisplay = item.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2").trim().replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
        if(itemString.length == 0 || itemString == null) {
          alertError(`${itemDisplay} is required`)
          foundError = true
          break;
        }
      }

      if(foundError){
        this.setState({
          dialogConfirmation: false,
          loadingEdit: false,
        })

        foundError = false

        return
      }
     
      // edit user itself
      resultEdit.payload = await editPartner({ partnerUid: partnerDetails.partnerUid, data: partnerDetails }) 
      resultEdit.payload = resultEdit.payload
      ProccessData(resultEdit, 'edit user')
      .then(res => {
        this.setState({ 
          loadingEdit: false,
         })
        this.props.getUsers({ data: { uid: this.props.match.params.id } })
        this.props.updateAccount(resultEdit.payload)
        this.onDialogInfo('Account Updated')
        this.props.history.go(-2)
      })
      .catch(err => {
        this.onDialogInfo(err)
        this.setState({
          dialogConfirmation: false,
          loadingEdit: false,
        })
      })
    } catch (e) {
      this.onDialogInfo(e.message)
      this.setState({
        loadingEdit: false,
      })
    }
  }

  _onOkDialog() {
    switch (this.state.dialogTitle) {
      case 'Delete Account': 
        this.deleteUser(this.state.partnerDetails.partnerUid)
        break
      case 'Reject Account':
        this.setState({ dialogReject: true, }) 
        break
      case 'Approve Account':
        this._onUpdateAccountStatus(ACCOUNT_STATUS.COMPLETED, 'Approve account')
        break
      case 'Delete Tag Account Relationship':
        this.deleteTagAccount(this.state.idTag)
        break
      default: null
    }
  }

  async _onUpdateAccountStatus(accountStatus, action) {
    this.setState({ loadingAccountStatus: true })
    let newError = new Error('ERROR')
    
    try {
      let resultUpdate = {}
      resultUpdate.fetching = false
      resultUpdate.payload = await updatePartnerAccountStatus({ uid: this.state.uid, accountStatus, processedBy: accountStatus === ACCOUNT_STATUS.COMPLETED ? `${this.props.account.payload.data.account.email}` : undefined, isArchived: 0, })
      ProccessData(resultUpdate, action)
      .then(res => {
        this.setState({ 
          dialogConfirmation: false,
          dialogReject: false,
          loadingAccountStatus: false,
         })
        this.onDialogInfo('Success')
         this.props.getUsers({ data: {} })
         this.props.history.goBack()
      })
      .catch(err => {
        this.setState({
          dialogConfirmation: false,
          dialogReject: false,
          loadingAccountStatus: false,
        })
        this.onDialogInfo(err)
      })
    } catch (e) {
      this.onDialogInfo(e)
      this.setState({
        loadingAccountStatus: false,
        dialogReject: false,
        dialogConfirmation: false,
      })
    }
  }

  onDialogInfo = (val) => {
    this.setState({infoDescription: val, infoDialogShow: true})
  }

  
  onTagAccount = async ({ user, successTagMessage, relationship, }) => {
    const { 
      uid,
      type, 
    } = this.state

    this.setState({ loadingTag: true, })
    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = await tagAccount({
        data: {
          "uidTags": this.state.uid,
          "uidTagged": user.uid,
          status: "ACCEPTED",
          relationship,
          tagFrom: 'ADMIN'
        }
      })
      ProccessData(resultGet, 'tag user')
        .then(async ({ data }) => {
           this.setState({
             dialogSuccessTag: true,
             successTagMessage,
           })
        })
        .catch(err => {
          alert(err ? err : 'ERROR')
        })
    } catch (e) {
      alert('CONNECTION ERROR ' + e)
    }
    
    this.setState({
      dialogTagCaregiver: false,
      dialogTagDementia: false,
      loadingTag: false,
    })
  }

  deleteTagAccount = async (id) => {
    this.setState({ loading: true })
    try {
      let resultDelete = {}
      resultDelete.fetching = false
      resultDelete.payload = await deleteOtherAccount({ id, })
      ProccessData(resultDelete, 'delete tag account')
      .then(async res => {
        this.setState({ 
          dialogConfirmation: false,
          loading: false,
          dialogSuccessTag: true,
          successTagMessage: 'Successfully Delete Account Tag',
         })
      })
      .catch(err => {
        alert(err ? err : 'ERROR')
        this.setState({
          dialogConfirmation: false,
          loading: false,
        })
      })
    } catch (e) {
      alert('CONNECTION ERROR ' + e)
      this.setState({
        loading: false,
        dialogConfirmation: false,
      })
    }
  }

  sendPushNotification = async ({ text, name, uidTarget, screenTitle, }) => {
    const { 
      uid,
      type, 
    } = this.state

    const data = {
      title: `New Tags Established`,
      text,
      name,
      platform: 'Android, iOS',
      type: 'ADMIN_TAGS',
      delivery: `NOW`,
      frequency: null,
      dateSend: moment(new Date()),
      uidTarget, 
      dataNotification: {
        targetScreen: 'MyOtherAccountScreen',
        screenTitle,
      }
    };
    
    let pushNotification = await addNotification(data)
    if (pushNotification.type === 'ERROR') alert('Failed to send push notification')
    return pushNotification
  }

  render() {
    const { history, } = this.props
    const { 
      user, 
      dialogConfirmation, 
      loading, 
      loadingEdit, 
      dialogTitle, 
      dialogDescription, 
      dialogOkText, 
      okBorderColor, 
      okTextColor, 
      titleColor, 
      dialogCancelText, 
      okBordered, 
      dialogReject,
      loadingAccountStatus, 
      otherAccount, 
      uid, 
      dialogTagCaregiver,
      dialogTagDementia, 
      dialogSuccessTag,
      successTagMessage,
      loadingTag,
      infoDialogShow,
      infoDescription,
      partnerDetails
    } = this.state

    const { organizationName, pocName, address, pocPhone, pocEmail, hotlineNumber, partnerUid, postalCode, jobTitle, branch, unitNumber } = partnerDetails 
    const { accountStatus, metadata } = user || ''
    
    const status = accountStatus === ACCOUNT_STATUS.INCOMPLETE ? 'Incomplete' : accountStatus === ACCOUNT_STATUS.COMPLETED ? 'Completed' : accountStatus === ACCOUNT_STATUS.PENDING_APPROVAL ? 'Pending approval' : accountStatus === ACCOUNT_STATUS.PENDING_CARDS ? 'Pending cards' : accountStatus === ACCOUNT_STATUS.REJECTED ? 'Rejected' : accountStatus === ACCOUNT_STATUS.TO_FOLLOW_UP ? 'To Follow-up' : accountStatus === ACCOUNT_STATUS.EXPIRED ? 'Expired' : '' 

    const color = accountStatus === ACCOUNT_STATUS.INCOMPLETE || accountStatus === ACCOUNT_STATUS.REJECTED || accountStatus === ACCOUNT_STATUS.EXPIRED || accountStatus === ACCOUNT_STATUS.TO_FOLLOW_UP ? Colors.color13 : accountStatus === ACCOUNT_STATUS.COMPLETED ? Colors.color17 : Colors.color18

    const { action } = this.props.location.state || ''
    const isDeleted = metadata && metadata.userDeleted

    // this.getOtherAccount(user)
    let isCaregiverDetail = user && user.type === 'CAREGIVER'

    return (
      <StyledUserDetail customStyles={` min-height: 100vh; `}>
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => this.setState({ dialogConfirmation: false, })}
          title={dialogTitle}
          showXClose={false}
          additionalStyle={{ titleStyle: ` color: ${titleColor}; ` }}
          description={dialogDescription}
          width={`37%`}
          height={`auto`}
          cancelText={dialogCancelText}
          okText={loading || loadingAccountStatus ? `Loading...` :  dialogOkText}
          okBorderColor={okBorderColor}
          okTextColor={okTextColor}
          onOk={this._onOkDialog}
          okBordered={okBordered}
        />
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <Dialog
          isVisible={dialogReject}
          isClose={() => this.setState({ dialogReject: false, })}
          title="Reject Account"
          description={`Tell the reason why you want to reject this account?`}
          width={`37%`}
          height={`auto`}
          cancelText={`Back`}
          okText={loadingAccountStatus ? 'Loading...' :`Submit`}
          onOk={() => this._onUpdateAccountStatus(ACCOUNT_STATUS.REJECTED, 'Reject account')}
          okBordered={false}
          okBackgroundColor={Colors.color10}
          okTextColor={Colors.color3}
        >
          <div>
            <Input
              multiline
              containerStyle={` margin-left: 14px; width: 96%; `} 
            />
          </div>
        </Dialog>
        <TagCaregiver
          dialogProps={{
            isVisible: dialogTagCaregiver,
            isClose: () => this.setState({ dialogTagCaregiver: false, }),
          }}
          otherAccount={otherAccount}
          loadingTag={loadingTag}
          onTagAccount={this.onTagAccount}
        />
        <TagDementia
          dialogProps={{
            isVisible: dialogTagDementia,
            isClose: () => this.setState({ dialogTagDementia: false, }),
          }}
          otherAccount={otherAccount}
          loadingTag={loadingTag}
          onTagAccount={this.onTagAccount}
        />
        <Dialog
          isVisible={dialogSuccessTag}
          isClose={() => {
            window.location.reload()
            this.setState({ dialogSuccessTag: false, })
          }}
          title={successTagMessage}
          showXClose={false}
          additionalStyle={{ 
            titleStyle: ` color: ${Colors.color2}; text-align: center;  `,
            buttonContainerStyle: ` margin: 35px 120px 10px 120px; `
          }}
          height={`auto`}
          okText="OK"
          okBordered={false}
          okBackgroundColor={Colors.color10}
          okTextColor={Colors.color3}
          onOk={() => {
            window.location.reload()
            this.setState({ dialogSuccessTag: false, }) 
          }}
        />
        <Container className="wrapper">
          {
            user &&
              <div>
                <Row align="center">
                  <Col lg={0.6} xs={1}>
                    <img src={Images.back} className="icon-back" onClick={() => history.goBack()} />
                  </Col>
                  <Col lg={11.4} xs={7}>
                    <Row align="center" justify="between">
                      <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                        <div className="title-wrapper">
                          <h1 className="title-partner-name">{`${pocName}`}</h1>
                          {
                            user.metadata.userDeleted && user.metadata.userDeletedReason ? (
                              <h1 className="title-status">Reason for Deletion: <span style={{ color: Colors.color13 }}>{user.metadata.userDeletedReason}</span></h1>  
                            ) : (
                              <h1 className="title-status">Status: <span style={{ color, }}>{status}</span></h1>  
                            )
                          } 
                        </div>
                        <div className="action">
                          {
                            (
                              this.props.account.payload && (partnerUid !== this.props.account.payload.data.account.uid )
                              &&
                              (accountStatus === ACCOUNT_STATUS.PENDING_APPROVAL || accountStatus === ACCOUNT_STATUS.INCOMPLETE || accountStatus === ACCOUNT_STATUS.PENDING_CARDS)
                              && !user.metadata.userDeleted
                              && !user.metadata.userDeletedReason
                            )
                              && 
                              <div className="buttonContainer">
                                <div style={{ width: '48%' }}>
                                  <Button 
                                    text="Reject"
                                    bordered
                                    textColor={Colors.color2}
                                    textStyle={` font-weight: bold; `}
                                    onClick={() => this.setState({ dialogConfirmation: true, ...rejectDialogState, })}
                                  />
                                </div>
                                <div style={{ width: '48%' }}>
                                  <Button 
                                    text="Approve"
                                    backgroundColor={Colors.color10}
                                    textStyle={` font-weight: bold; `}
                                    onClick={() => this.setState({ dialogConfirmation: true, ...approveDialogState, })}
                                  />
                                </div>
                              </div>
                          }
                          {
                            (this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && action !== 'EDIT' && !isDeleted &&
                              <div onClick={() => this.props.history.push({
                                pathname: `/partner/${partnerUid}`,
                                state: {
                                  action: 'EDIT'
                                }
                                })}
                              >
                                <div className="edit-wrapper">
                                  <img src={Images.edit} className="edit" />
                                </div>
                              </div>
                          }
                          {
                            this.props.account.payload && (partnerUid !== this.props.account.payload.data.account.uid ) && (this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN) && 
                              <div onClick={() => this.setState({ dialogConfirmation: true, ...deleteDialogState, })}>
                                <div className="trash-wrapper">
                                  <img src={Images.trash} className="delete" />
                                </div>
                              </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>    
                  <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                    <h1 className="subtitle">Personal Details</h1>
                  </Col>
                  {
                    [
                      this.renderList('organizationName', 'Organization Name', organizationName, 'list-dark'),
                      this.renderList('branch', 'Branch', branch, '', {}),
                      this.renderList('postalCode', 'Postal Code', postalCode, 'list-dark',  {}, { type: 'number', }),
                      this.renderList('address', 'Address', address, '', {}, { maxLength: 200, }),
                      this.renderList('unitNumber', 'Unit Number', unitNumber, '', {}),
                      this.renderList('pocName', 'Name of main point of contact (P.O.C)', pocName, 'list-dark'),
                      this.renderList('jobTitle', 'Title of main P.O.C', jobTitle, '', {}),
                      this.renderList('pocPhone', 'Phone Number of P.O.C', pocPhone),
                      this.renderList('pocEmail', 'Email of P.O.C', pocEmail, 'list-dark'),
                      this.renderList('hotlineNumber', 'Centre Number', hotlineNumber),
                    ]
                  }
                </Row>
              </div>

          }
        </Container>
        <div style={{ height: '20px', }} />
        {
          (otherAccount && otherAccount.length > 0 && action !== 'EDIT') &&
            otherAccount.map((item, index) => {
              const { firstname, lastname, gender, birth, dementiaDetail, email, phone } = item.Account
              return (
                <>
                  <Container className="wrapper">
                    {
                      user &&
                        <Row>    
                          <Col offset={{ lg: 0.6, xs: 1 }} xs={11} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', }}>
                            <div>
                              <h1 className="subtitle" style={{ marginTop: 0, }}>Person Living with Dementia {index > 0 ? index + 1 : ''}</h1>
                            </div>
                            <div className="trash-wrapper" onClick={() => this.setState({ dialogConfirmation: true, idTag: item.id, ...deleteTagDialogState, })}>
                              <img src={Images.trash} className="delete" />
                            </div>
                          </Col>
                          {
                            [
                              this.renderList('otherFirstname', 'First Name', firstname, 'list-dark'),
                              this.renderList('otherLastname', 'Last Name', lastname),
                              this.renderList('relationship', `Relationship to ${user.type === 'DEMENTIA' ? 'Person with Dementia' : 'Caregiver'}`, item.relationship, 'list-dark'),
                              this.renderList('otherGender', 'Gender', gender),
                              this.renderList('otherBirth', 'Date of Birth', moment(birth).format('DD MMMM YYYY'), 'list-dark'),
                            isCaregiverDetail &&    
                              this.renderList('otherLanguage', 'Preferred Language of Communication', dementiaDetail ? dementiaDetail.language : '-'),
                              this.renderList('otherEmail', 'Email Address', email, 'list-dark'),
                              this.renderList('otherPhone', 'Phone Number', phone, '', {}, { type: 'number' }),
                            ]
                          }
                        </Row>
                    }
                  </Container>
                  <div style={{ height: '20px', }} />
                </>
              )
            })
        }
        {
          (user && action === 'EDIT') ?
            <div className="button-wrapper">
              <div style={{ width: '211px', }}>
                <Button
                  text="Discard"
                  textColor={Colors.color11}
                  bordered
                  buttonStyle={` background: ${Colors.primaryBgColor}; `}
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div style={{ width: '15px', }} />
              <div style={{ width: '211px', }}> 
                <Button
                  text={loadingEdit ? 'Loading...' : "Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={this.onEditAccount}
                  disabled={loadingEdit}
                />
              </div>
            </div>
          : 
            null
            // <div className="button-wrapper">
            //   <Button
            //     text={`Tag new ${isCaregiverDetail ? 'Person Living with Dementia' : 'Caregiver'}`}
            //     textColor={Colors.color11}
            //     bordered
            //     buttonStyle={` background: ${Colors.primaryBgColor}; `}
            //     onClick={() => {
            //       let dialogName = isCaregiverDetail ? 'dialogTagDementia' : 'dialogTagCaregiver'
            //       this.setState({
            //         [dialogName]: true,
            //       })
            //     }}
            //     buttonStyle={` background: transparent; width: 315px; `}
            //   />
            // </div>
        }
      </StyledUserDetail>
    )
  }
}

const mapStateToProps = (state) => ({
   users: state.user.users,
   account: state.auth.account,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(UserActions.getUsersRequest(params)),
    updateAccount: (params) => dispatch(AuthActions.updateAccount(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetail)