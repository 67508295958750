import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import { StyledImportUser } from './styles/StyledImportUser'

import Table from '../components/table'
import Dialog from '../components/modal'
import Input from '../components/input'
import Button from '../components/button'
import FilterUser from '../components/filter-user'
import moment from 'moment'

import { Colors, Images } from '../themes'

import { validateCSVData, bulkCreateAccount } from '../api/UserAPI'

import ProccessData from '../lib/ProccessData'
import images from '../themes/Images'

import { alertError } from '../components/alert'
import { USER_TYPE } from '../constants'

const duplicatePage = {
  page: 'DUPLICATE',
  buttonTitle: 'Duplicates Data',
  infoText: 'Duplicated data',
  dataName: 'duplicate'
}

const incompletePage = {
  page: 'INCOMPLETE',
  buttonTitle: 'Incomplete Data',
  infoText: 'Incomplete data',
  dataName: 'incompleteData'
}

const completePage = {
  page: 'COMPLETE',
  buttonTitle: 'Complete Data',
  infoText: 'Completed data',
  dataName: 'complete'
}

const successPage = {
  page: 'SUCCESS',
  buttonTitle: 'Data Successfuly Upload',
  infoText: 'User successfully upload',
  dataName: ''
}

const filterUserDefaultState = {
  filterBy: [],
  additionalFilter: { typeDementia: '', gender: '', language: '', },
}

export class ImportUser extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      ...completePage,
      ...filterUserDefaultState,
      loading: false,
      search: '',
      userData: {},
      originalUserData: {},
      successImport: false,
      loadingImport: false,
      successData: [],
      accountType: ''
    }
  }

  componentDidMount() {
    this.processCSVData()
  }

  processCSVData = async () => {
    const { file } = this.props.location.state
    this.setState({ loading: true })
    try {
      let resultGet = {}
      const data = new FormData() 
      data.append('file', file)
      resultGet.payload = await validateCSVData(data)
      ProccessData(resultGet, 'get users')
      .then(res => {
        this.setState({ 
          loading: false, 
          userData: res.data, 
          originalUserData: res.data, 
          accountType: res.data.accountType
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        alertError({ text: err ? err : 'Something wrong, please check your internet connection', })
      })
    } catch (e) {
      this.setState({ loading: false })
      alertError({ text: e ? e : 'Something wrong, please check your internet connection', })
    }
  }

  renderColumn = ({ header, text = 'TEST', width = 150, }) => {
    return  {
      Header: props => <span style={{ display: 'inline-block', margin: '10px' }}>{header}</span>,
      Cell: props => {
        const { page, userData } = this.state
        if (header === `Status`) {
          let registrationStatus = {}
          registrationStatus = 
            !_.isEmpty(userData) ?
              page === 'DUPLICATE' ?
                props.original.existingInDb ? { 
                  text: 'Already in database', 
                  color: Colors.color13 
                } : {
                  text: 'Duplicate', 
                  color: Colors.color13
                }
              : page === 'INCOMPLETE' ?
                {
                  text: 'Data is not complete', 
                  color: Colors.color14
                }
              :  
                {
                  text: 'Complete', 
                  color: Colors.color37
                }
            :   
              {
                text: 'Success', 
                color: Colors.color37
              }
          return (
            <span className="column-text" style={{ margin: '10px', color: registrationStatus.color }} onClick={() => null}>{registrationStatus.text}</span>
          )
        } 

        else if (header === 'Date of Birth') {
          const formattedDate = moment(props.original[text]).format('DD MMMM YYYY')
          return (
            <span className="column-text" style={{ margin: '10px' }} onClick={() => null}>{formattedDate}</span>
          )
        }

        else if (header === `Send physical CARA card to partner organisation address`) {
          const cardAddress = 
            page === 'SUCCESS' ?
              props.original[text] ? "Y" : "N"
            :
              props.original[text]
          return (
            <span className="column-text" style={{ margin: '10px' }} onClick={() => null}>{cardAddress}</span>
          )
        }

        else {
          return (
            <span className="column-text" style={{ margin: '10px' }} onClick={() => null}>{props.original[text]}</span>
          )
        }
      }, 
      headerClassName: 'table-header',
      className: 'column',
      width,
      sortable: false,  
    }
  }

  columns = (tableType = this.state.accountType) => {
    const columnsData = tableType === USER_TYPE.DEMENTIA ? [
      this.renderColumn({ header: 'Status', width: 100 }),
      this.renderColumn({ header: `Username`, text: 'username' }),
      // this.renderColumn({ header: `Password`, text: 'password' }),
      this.renderColumn({ header: `Firstname`, text: 'firstname'  }),
      this.renderColumn({ header: `Lastname`, text: 'lastname' }),
      this.renderColumn({ header: `Date of Birth`, text: 'birth' }),
      this.renderColumn({ header: `Street Name`, text: 'streetName' }),
      this.renderColumn({ header: `Unit Number`, text: 'unitNumber' }),
      this.renderColumn({ header: `Postal Code`, text: 'postalCode' }),
      this.renderColumn({ header: `City Country`, text: 'cityCountry' }),
      this.renderColumn({ header: `Display Name`, text: 'displayName' }),
      this.renderColumn({ header: `Last NRIC`, text: 'lastNric' }),
      this.renderColumn({ header: `Phone`, text: 'phone' }),
      this.renderColumn({ header: `Email`, text: 'email' }),
      this.renderColumn({ header: `Gender`, text: 'gender' }),
      this.renderColumn({ header: `Home Phone`, text: 'homeNumber' }),
      this.renderColumn({ header: `Send physical CARA card to partner organisation address`, text: 'cardAddress', width: 500 }),
    ] : [
      // this.renderColumn({ header: 'Status', width: 100 }),
      this.renderColumn({ header: `Firstname`, text: 'firstname'  }),
      this.renderColumn({ header: `Lastname`, text: 'lastname' }),
      this.renderColumn({ header: `Date of Birth`, text: 'birth' }),
      this.renderColumn({ header: `Street Name`, text: 'streetName' }),
      this.renderColumn({ header: `Unit Number`, text: 'unitNumber' }),
      this.renderColumn({ header: `Postal Code`, text: 'postalCode' }),
      this.renderColumn({ header: `City Country`, text: 'cityCountry' }),
      // this.renderColumn({ header: `Display Name`, text: 'displayName' }),
      this.renderColumn({ header: `Last NRIC`, text: 'lastNric' }),
      this.renderColumn({ header: `Phone`, text: 'phone' }),
      this.renderColumn({ header: `Email`, text: 'email' }),
      this.renderColumn({ header: `Gender`, text: 'gender' }),
      this.renderColumn({ header: `Home Phone`, text: 'homeNumber' }),
      this.renderColumn({ header: `Job Title`, text: 'jobTitle' }),
      this.renderColumn({ header: `Department`, text: 'department' }),
      // this.renderColumn({ header: `Send physical CARA card to partner organisation address`, text: 'cardAddress', width: 500 }),
    ]
    return columnsData
  }

  onPrevious = () => {
    const { page, } = this.state

    if (page !== 'COMPLETE') {
      let prevPage = {}
      switch (page) {
        case 'DUPLICATE':
          prevPage = incompletePage
          break
        case 'INCOMPLETE':
          prevPage = completePage
          break
        default: 
          null
      }

      this.setState({ ...prevPage })
    }
  }

  onNext = () => {
    const { page, } = this.state

    if (page !== 'DUPLICATE') {
      let nextPage = {}
      switch (page) {
        case 'COMPLETE':
          nextPage = incompletePage
          break
        case 'INCOMPLETE':
          nextPage = duplicatePage
          break
        default: 
          null
      }
      
      this.setState({ ...nextPage })
    }
  }

  onCheckFilter = (value) => {
    const filterBy = [...this.state.filterBy]

    if (!filterBy.includes(value.params)) {          //checking weather array contain the id
      filterBy.push(value.params);               //adding to array because value doesnt exists
    } else {
      filterBy.splice(filterBy.indexOf(value.params), 1);  //deleting
    }
    
    this.setState({ filterBy, })
  }

  onSetAdditionalFilter = (value) => {
    this.setState({
      additionalFilter: {
        ...this.state.additionalFilter,
        ...value,
      },
    })
  }

  renderTableHeader = () => {
    const { page, buttonTitle, search, infoText, accountType, originalUserData, filterBy, additionalFilter, userData, } = this.state
    const totalData = 
      !_.isEmpty(originalUserData) ?
        page === 'DUPLICATE' ?
          _.union(originalUserData.duplicate, originalUserData.existingData).length
        : page === 'INCOMPLETE' ?
          originalUserData.incompleteData.length
        :  
          originalUserData.complete.length
      : 0
    return (
      <div>
        <div className="button-container">
          <container  fluid style={{ padding: 0 }}>
            <Row nogutter>
              <Col md={7} style={{ margin: '5px 0' }}>
                <div className="top-left-container">
                  {
                    page !== "SUCCESS" ?
                      <>
                        <Button
                          text={completePage.buttonTitle}
                          textStyle="margin: 0 15px"
                          backgroundColor={page === "COMPLETE" ? undefined : "transparent"}
                          buttonStyle={` box-shadow: none `}
                          textColor={page === "COMPLETE" ? undefined : Colors.color11}
                          onClick={() =>  this.setState({ ...completePage })}
                        />
                        <div style={{ width: '10px' }} />
                        <Button
                          text={incompletePage.buttonTitle}
                          textStyle="margin: 0 15px"
                          backgroundColor={page === "INCOMPLETE" ? undefined : "transparent"}
                          buttonStyle={` box-shadow: none `}
                          textColor={page === "INCOMPLETE" ? undefined : Colors.color11}
                          onClick={() =>  this.setState({ ...incompletePage })}
                        />
                        <div style={{ width: '10px' }} />
                        <Button
                          text={duplicatePage.buttonTitle}
                          textStyle="margin: 0 15px"
                          backgroundColor={page === "DUPLICATE" ? undefined : "transparent"}
                          buttonStyle={` box-shadow: none `}
                          textColor={page === "DUPLICATE" ? undefined : Colors.color11}
                          onClick={() =>  this.setState({ ...duplicatePage })}
                        />
                      </>  
                    :
                      <Button
                        text={buttonTitle}
                        textStyle="margin: 0 15px"
                        backgroundColor="transparent"
                        buttonStyle={` box-shadow: none `}
                        textColor={Colors.color11}
                      />
                  }
                </div>
              </Col>
              <Col md={5} style={{ margin: '5px 0' }}>
                <div className="top-right-container">
                  {
                    page === 'SUCCESS' ?
                      <div>
                        <Button
                          text="Membership Management"
                          textStyle="margin: 0 26px"
                          backgroundColor={Colors.color17}
                          iconRight
                          iconRightImage={Images.arrowRightOutline}
                          iconRightStyle={`
                            width: 16px;
                            margin-right: 26px;
                          `}
                          onClick={() => this.props.history.replace('/users')}
                        />
                      </div>
                    :
                      <div className="arrow-container">
                        <div style={{ display: 'flex', alignItems: 'center', cursor: page === 'COMPLETE' ? 'not-allowed' : 'pointer' }} onClick={this.onPrevious}>
                          <div>
                            <img src={
                              page === 'COMPLETE' ?
                                images.arrowLeftDisable
                              :
                                Images.arrowLeft
                            } alt="arrow" style={{ width: '40px', height: '40px', objectFit: 'contain', marginRight: '16px' }} />
                          </div>
                          <div className="arrow-text" style={{ 
                            color: page === 'DUPLICATE' ? Colors.color19 : Colors.color38
                           }}>
                            Previous
                          </div>
                        </div>
                        <div style={{ width: '72px', }} />
                        <div style={{ display: 'flex', alignItems: 'center', cursor: page === 'DUPLICATE' ? 'not-allowed' : 'pointer' }} onClick={this.onNext}>
                          <div className="arrow-text" style={{
                            color: page === 'DUPLICATE' ? Colors.color19 : Colors.color38
                          }} >
                            Next
                          </div>
                          <div>
                            <img src={
                              page === 'DUPLICATE' ?
                                images.arrowRightDisable
                              :
                                Images.arrowRight
                            } alt="arrow" style={{ width: '40px', height: '40px', objectFit: 'contain',marginLeft: '18px' }} />
                          </div>
                        </div>
                      </div>
                  }
                </div>
              </Col>
            </Row>
          </container>
        </div> 
        <div className="filter-container">
          <Container  fluid style={{ padding: 0 }} style={{ }}>
            <Row nogutter>
              <Col md={8} style={{  }}>
                {/* <Input
                  inputProps={{
                    placeholder: 'Search',
                    value: search,
                    onChange: (e) => this.setState({ search: e.target.value })
                  }}
                  containerStyle={`
                    background: transparent;
                    width: 80%;
                  `}
                  iconRight={search === '' ?  Images.search : undefined}
                  iconRightStyle={`
                    position: absolute;
                    left: 100px;
                    top: 35px;
                    wdith: 14px;
                    height: 14px;
                  `}
                />
                <FilterUser 
                  checkboxData={checkboxData}
                  onCheckFilter={this.onCheckFilter}
                  onSetAdditionalFilter={this.onSetAdditionalFilter}
                  showDropdownFilter={
                    accountType === USER_TYPE.CAREGIVER ? {
                      gender: true,
                      typeDementia: false,
                      language: false,
                    } :
                    undefined
                  }

                /> */}
              </Col>
              <Col md={4}>
                <div className="search-right-container">
                  {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                    <div className="button-search-container">
                      <Button
                        text="Search"
                        onClick={this.onSearch}
                        disabled={
                          (
                            (
                              filterBy.length < 1 &&
                              additionalFilter.typeDementia === '' &&
                              additionalFilter.gender === '' &&
                              additionalFilter.language === ''
                            ) && search !== ''
                          ) 
                          // ||
                          // (
                          //   (
                          //     filterBy.length > 0 || 
                          //     additionalFilter.typeDementia !== '' ||
                          //     additionalFilter.gender !== '' ||
                          //     additionalFilter.language !== ''
                          //   ) && search === ''
                          // ) 
                        }
                      />
                      <Button
                        text="Reset FIlter"
                        containerStyle="margin-top: 10px;"
                        onClick={() =>  this.setState({ userData: this.state.originalUserData })}
                      />
                    </div>
                  </div> */}
                  <div style={{ display: 'flex', height: '60%', margin: '40px', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <span className="info-text">{infoText}: </span><span className="info-text" style={{ marginLeft: '5px', color: Colors.color11, fontWeight: 'bold' }}>{totalData}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>  
      </div>
    )
  }

  onSearch = () => {
    const { originalUserData, search, filterBy, userData, additionalFilter, dataName, page, accountType, } = this.state
    
    let searchResult = []
    const originalDataToFilter = page === 'DUPLICATE' ? _.union(userData.duplicate, userData.existingData) : userData[dataName]
    const savedOriginalData = {...originalUserData} 

    const currentUserData = userData

    if (filterBy.length > 0 && search !== '') {
      filterBy.map((item) => {
        originalDataToFilter.filter(data => {
          if (data[item].toLowerCase().includes(search.toLowerCase())) { 
            searchResult.push(data);
          }
        });
      })
      
      currentUserData[dataName] = searchResult
      if (page === 'DUPLICATE') currentUserData['existingData'] = searchResult

      this.setState({
        userData: currentUserData,
        originalUserData: savedOriginalData,
      })
    }

    else if (additionalFilter.gender !== '' || additionalFilter.typeDementia !== '' || additionalFilter.language !== '') {
      if (additionalFilter.gender !== '') {
        originalDataToFilter.filter(data => {
          if (additionalFilter.gender && data['gender'].toLowerCase() === additionalFilter.gender.toLowerCase()) { 
            searchResult.push(data);
          }
        });
        currentUserData[dataName] = searchResult
        if (page === 'DUPLICATE') currentUserData['existingData'] = searchResult
      }

      if (additionalFilter.typeDementia !== '') {
        originalDataToFilter.filter(data => {
          if (additionalFilter.typeDementia && data['typeDementia'].toLowerCase().includes(additionalFilter.typeDementia.toLowerCase())) { 
            searchResult.push(data);
          }
        });
        currentUserData[dataName] = searchResult
        if (page === 'DUPLICATE') currentUserData['existingData'] = searchResult
      }

      if (additionalFilter.language !== '') {
        originalDataToFilter.filter(data => {
          if (additionalFilter.language && data['language'].toLowerCase().includes(additionalFilter.language.toLowerCase())) { 
            searchResult.push(data);
          }
        });
        currentUserData[dataName] = searchResult
        if (page === 'DUPLICATE') currentUserData['existingData'] = searchResult
      }


      this.setState({
        userData: currentUserData,
        originalUserData: savedOriginalData,
      })
    }

    else {
      this.setState({ userData: originalUserData })
    }
  }

  onCreateBulkAccount = async () => {
    this.setState({ loadingImport: true, })
    try {
      const data = this.state.originalUserData.complete
      let resultAdd = {}
      resultAdd.fetching = false
      resultAdd.payload = await bulkCreateAccount({ data })
      ProccessData(resultAdd, `add bulk account`)
      .then(res => {
        this.setState({ 
          loadingImport: false, 
          successImport: true,
          successData: res.data,
          ...successPage,
        })
      })
      .catch(err => {
        this.setState({ 
          loadingImport: false, 
        })
        alertError({ text: err, })
        
      })
    } catch (e) {
      this.setState({ 
        loadingImport: false, 
      })
      alertError({ text: e, })
    }
  }

  render() {
    const { loading, page, userData, dataName, successImport, loadingImport, successData, filterBy, additionalFilter } = this.state

    return (
      <StyledImportUser>
        <Container fluid style={{ padding: 0 }} style={{ marginBottom: '25px' }}>
          <Row nogutter>
            <Col md={6} style={{ display: 'flex', alignItems: 'center' }}>
              <h1 className="title">Import Users</h1>
            </Col>
            <Col md={6} className="top-button-container">
              {
                !successImport &&
                  <>
                    <div style={{ width: '172px' }}>
                      <Button
                        text={loadingImport ? "Importing..." : "Import"}
                        backgroundColor={Colors.color17}
                        disabled={(loading) || (userData.complete && userData.complete.length < 1)}
                        onClick={this.onCreateBulkAccount}
                      />
                    </div>
                    <div style={{ width: '20px' }} /> 
                    <div style={{ width: '119px' }}>
                      <Button
                        text="Cancel X"
                        backgroundColor={Colors.color13}
                        onClick={() => this.props.history.goBack()}
                      />
                    </div>
                  </>
              }
            </Col>
          </Row>
        </Container>
        <Table
          columns={this.columns()}
          responsive
          data={
            successImport ?
              successData
            :
              page === 'DUPLICATE' ?
              _.union(userData.duplicate, userData.existingData)
            :  userData[dataName]
            
          }
          loading={loading}
          dataLimit={10}
          pageSize={10}
          tableProps={{ showPageSizeOptions: false, showPagination: true, }}
          tableHeader={this.renderTableHeader()}
          />
      </StyledImportUser>
    )
  }
}

const checkboxData = [
  {
    name: 'First Name',
    params: 'firstname',
  },
  { 
    name: 'Last Name',
    params: 'lastname',
  },
  {
    name: 'Email',
    params: 'email',
  },
]

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportUser)
