import React, { useState, Fragment, useEffect } from 'react'

import { StyledLogin, Wrapper,  buttonAllAccounts, buttonAllAplicants } from './styles/StyledLogin'
import { Typography, Colors } from '../themes'
import Container from '../components/container'
import Input from '../components/input'
import Button from '../components/button'
import { alert } from '../components/alert'
import RadioButton from '../components/radio-button/RadioButton'

import { getSingleAccount } from '../api/UserAPI'
import ProccessData from '../lib/ProccessData'


const { Title, SubTitle, ErrorText } = Typography

function Forget({
  location,
  history,
}) { 
  const [state, setState] = useState({
    forgetPassword: {
      username: '',
      pocEmail: '',
      email: '',
      roleType: 'PARTNER'
    },
    forgetUsername: {
      pocEmail: '',
      organizationName: ""
    },
    errorText: false,
    roleType: 'PARTNER',
    roleTypes: [
      {
        value: 'PARTNER',
        name: 'Partner Account'
      },
      {
        value: 'ADMIN',
        name: 'Admin Account'
      }
    ],
    checkedadmin: false,
    checkedpartner: true
  })
  const [disableResetButton, setDisableResetButton] = useState(true)

  const isForgetPassword = location.pathname === '/forget-password'
  const data = isForgetPassword ? {
    stateName: `forgetPassword`,
    title: `Forget Password`,
    infoText: `Input your Email ${state.forgetPassword.roleType === 'PARTNER' ? 'of P.O.C' : '' } or Username to request reset password link`,
    field: `username`,
    placeholder: `Username`,
    buttonText: "Reset Password",
    buttonDisabled: disableResetButton,
    onSuccess: () => {
      alert({ 
        icon: 'success', 
        title: "Success!", 
        text: 'Request reset password success. Please check your email.',
        preConfirm: () => {
          history.goBack()
        }
      })
    }
  } : {
    stateName: `forgetUsername`,
    title: `Forget Username`,
    infoText: 'Input your Email of P.O.C and Organisation Name to show you current username',
    field: `organizationName`,
    placeholder: `Organisation Name`,
    buttonText: "Continue",
    buttonDisabled: state.forgetUsername.pocEmail === '' || state.forgetUsername.organizationName === '',
    onSuccess: (account) => {
      history.push('show-username', { account })
    }
  }

  const { pocEmail, email } = state[data.stateName]
  const { errorText } = state
  
  const onSetState = (newState) => {
    setState(state => {
      const valueNewState =  typeof newState == "function" ? newState(state) : newState
      typeof newState == "function" && newState(state)
      return {
        ...state,
        ...valueNewState
      }
    })
  }

  const _onChangeText = (name, value) => {
    onSetState({
      [data.stateName]: {
        ...state[data.stateName],
        [name]: value
      }
    })
  }

  const onSubmit = async () => {
    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = await getSingleAccount({ data: {
        ...state[data.stateName],
        type: state.forgetPassword.roleType,
        resetPassword: isForgetPassword,
      } })
      ProccessData(resultGet, 'get single account')
      .then(res => {
        if (res.type === "SUCCESS") {
          data.onSuccess(res.data.account)
        } else {
          if (res.payload.data.message) alert({ 
            icon: 'error', 
            title: "Failed!", 
            text: res.payload.data.message,
          }) //onSetState({ errorText: res.payload.data.message })
        }
      })
      .catch(err => {
        console.log('err => ', err)
        alert({ 
          icon: 'error', 
          title: "Failed!", 
          text: err,
        })
      })
    } catch (e) {
      alert(e)
      onSetState({ errorText: e })
    }
  }

  const handleRadioButton = (e) => {
    setState(state => {
      let valueNewState

      if(e === 'ADMIN') {
        valueNewState = {
          forgetPassword: {
            ...state.forgetPassword,
            pocEmail: '',
            username: ''
          },
          checkedadmin: true,
          checkedpartner: false
        }
      } else if(e === 'PARTNER') {
        valueNewState = {
          forgetPassword: {
            ...state.forgetPassword,
            email: '',
            username: ''
          },
          checkedadmin: false,
          checkedpartner: true
        }
      }

      return {
        ...state,
        ...valueNewState
      }
    })
  }

useEffect(() => {
  if((state.forgetPassword.pocEmail || state.forgetPassword.email) && state.forgetPassword.username){
    setDisableResetButton(false)
  } else {
    setDisableResetButton(true)
  }
}, [state.forgetPassword])
  

  return (
    <Container>
      <StyledLogin>
        <Wrapper style={{ minWidth: '450px' }}>
          <Title customStyle={` color: black; `}>{data.title}</Title>
          <SubTitle customStyles={` color: black; margin-top: 10px; `}>{data.infoText}</SubTitle>
          {/* {JSON.stringify(state)} */}
          {
            isForgetPassword && (
              <div style={{
                  display: 'flex',
                  width: '80%',
                  justifyContent: 'space-between',
                  margin: '15px 0 -5px 0'
                }}>
                  {
                    state.roleTypes.map((item, index) => (
                      <Fragment key={index}>
                        <RadioButton name={`role`} value={item.value} onChange={(e) => {
                          _onChangeText('roleType', e.target.value)
                          handleRadioButton(e.target.value)
                          // onChangeText('type', e.target.value)
                        }} placeholder={item.name}  checked={state[`checked${item.value.toLowerCase().split('_').join('')}`]} />
                      </Fragment>
                    ))
                  }
              </div>
            )
          }
          {
            state.forgetPassword.roleType == 'PARTNER' ? (
              <Input inputProps={{ placeholder: "Email of P.O.C", onChange: (e) => _onChangeText('pocEmail', e.target.value), value: pocEmail, maxLength: 50, }} containerStyle={` margin-top: 33px; `} />
            ) : (
              <Input inputProps={{ placeholder: "Email", onChange: (e) => _onChangeText('email', e.target.value), value: email, maxLength: 50, }} containerStyle={` margin-top: 33px; `} />
            )
          }
          <Input inputProps={{ 
              placeholder: data.placeholder, 
              onChange: (e) => _onChangeText(data.field, e.target.value), 
              value: isForgetPassword ? state.forgetPassword.username : [data.stateName].field, maxLength: 50, 
            }} 
            containerStyle={` margin-bottom: 33px; `} 
          />
          {
            errorText !== '' &&
              <ErrorText customStyles={`margin-bottom: 15px;`}>
                {errorText}
              </ErrorText>
          }
          <div className="buttonContainer">
            <div style={{ flex: 1, }}>
              <Button
                text="Back"     
                backgroundColor={Colors.primaryBgColor}    
                textColor={Colors.color11}
                bordered
                buttonStyle={`border-radius: 15px; `} 
                onClick={() => history.goBack()}
              />
            </div>
            <div className="buttonSeparator" />
            <div style={{ flex: 1, }}>
              <Button 
                text={data.buttonText}
                onClick={onSubmit}
                disabled={data.buttonDisabled}
              />

            </div>
          </div>
        </Wrapper>
      </StyledLogin>
    </Container>
  )
}

export default Forget
