import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Container from '../components/container'

import { USER_TYPE } from '../constants/'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

import StartupActions from '../redux/StartupRedux'
import BenefitsActions from '../redux/BenefitsRedux'
import DashboardAction from '../redux/DashboardPartner'

export class RootContainer extends Component {

  componentDidMount() {
    this.props.initToken()
    this.props.getBenefitCategories({ data: {} })
    this.props.getDashboardPartner()
    localStorage.getItem("benefitPage") ? null : localStorage.setItem("benefitPage", 1)
    localStorage.getItem("solutionPage") ? null : localStorage.setItem("solutionPage", 1)
    localStorage.getItem("dementiaPage") ? null : localStorage.setItem("dementiaPage", 1)
    localStorage.getItem("caregiverPage") ? null : localStorage.setItem("caregiverPage", 1)
    localStorage.getItem("showUser") ? null : localStorage.setItem("showUser", USER_TYPE.DEMENTIA)
    localStorage.getItem("listUserBy") ? null : localStorage.setItem("listUserBy", "ALL_ACCOUNTS")
    localStorage.getItem("searchMaxUser") ? null : localStorage.setItem("searchMaxUser", AppConfig.DEFAULT_LIMIT_USER,)
  }

  render() { 
    const { account } = this.props

     if (account && account.payload) { 
      return <Redirect to="/users" />
      //  if (account.payload.type === 'SUCCESS') {
      //    if (account.payload.data.account.type === USER_TYPE.ADMIN) {
      //       return <Redirect to="/users" />
      //    }
      //    if (account.payload.data.account.type === USER_TYPE.PARTNER) {
      //     return <Redirect to="/dashboard" />
      //    }
      //  }
    }

    return <Redirect to="/login" />
  }
}

const mapStateToProps = (state) => ({
  account: state.auth.account
})

const mapDispatchToProps = (dispatch) => {
  return {
    initToken: () => dispatch(StartupActions.initToken()),
    getBenefitCategories: (params) => dispatch(BenefitsActions.getCategoriesRequest(params)),
    getDashboardPartner: (params) => dispatch(DashboardAction.getDashboardRequest(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
